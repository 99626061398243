import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import axios from 'axios';
import { environment } from '../environments/environment';
import { SideNavComponent } from "./side-nav/side-nav.component";
import { LoginService } from './login.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SideNavComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {

  constructor(private loginService: LoginService) { }

  title = 'predicta-front';
  isLogInPage: boolean = false;

  ngOnInit() {
    this.loginService.ifLoggedIn(this.verifyUser.bind(this));
    this.isLogInPage = window.location.pathname === '/';
  }

  async verifyUser() {
      const user_data = (await axios.get(environment.apiUrl + '/whoami', { headers: { Authorization: `Bearer ${this.loginService.token}` } })).data;
      if (!user_data.ativo) (document.querySelector('dialog#deactivated-user') as HTMLDialogElement)?.showModal();
  }

  logOut() {
    this.loginService.logout();
  }
}
