import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { LoginService } from '../login.service';

@Component({
  selector: 'reprocessos',
  standalone: true,
  imports: [],
  templateUrl: './reprocessos.component.html',
  styleUrl: './reprocessos.component.css'
})
export class ReprocessosComponent {

  constructor(private loginService: LoginService) {
  }

  isLoading: boolean = true;

  async getData() {
    this.isLoading = true;

    const total_em_estoque_kg_element = document.getElementById('total_em_estoque_kg');
    const total_em_estoque_percent_element = document.getElementById('total_em_estoque_percent');
    const total_reprocessos_gerados_kg_element = document.getElementById('total_reprocessos_gerados_kg');

    try {
      const response = await axios.get(environment.apiUrl + '/reprocessos/infos', { headers: { Authorization: 'Bearer ' + this.loginService.token } });
      const data = response.data;
      const formatador = new Intl.NumberFormat('pt-BR', { 
        style: 'decimal', 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      });

      const total_em_estoque_kg = formatador.format(data.total_em_estoque_kg);
      const total_em_estoque_percent = formatador.format(data.total_em_estoque_percent);
      const total_reprocessos_gerados_kg = formatador.format(data.total_reprocessos_gerados_kg);

      if (total_em_estoque_kg_element) total_em_estoque_kg_element.innerHTML = total_em_estoque_kg;
      if (total_em_estoque_percent_element) total_em_estoque_percent_element.innerHTML = total_em_estoque_percent;
      if (total_reprocessos_gerados_kg_element) total_reprocessos_gerados_kg_element.innerHTML = total_reprocessos_gerados_kg;
    } catch (error) {
      if (total_em_estoque_kg_element) total_em_estoque_kg_element.innerHTML = 'Erro';
      if (total_em_estoque_percent_element) total_em_estoque_percent_element.innerHTML = 'Erro';
      if (total_reprocessos_gerados_kg_element) total_reprocessos_gerados_kg_element.innerHTML = 'Erro';
    } finally {
      this.isLoading = false;
    }
  }

  ngOnInit() {
    this.loginService.ifLoggedIn(this.getData.bind(this));
  }

}
