import { Component } from '@angular/core';
import axios, { AxiosError } from 'axios';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';
import { LoginService } from '../login.service';
import { Title } from '@angular/platform-browser';
import { QuickAccessButtonsComponent } from "../quick-access-buttons/quick-access-buttons.component";

@Component({
  selector: 'reprocessos-dados',
  standalone: true,
  imports: [CommonModule, QuickAccessButtonsComponent],
  templateUrl: './reprocessos-dados.component.html',
  styleUrl: './reprocessos-dados.component.css'
})
export class ReprocessosDadosComponent {

  constructor(private title: Title, private loginService: LoginService) { 
    this.title.setTitle('Reprocessos - Predicta');
  }

  isLoading: boolean = false;

  produtosSelect: any[] = [];
  datas: any[] = [];
  filterQuery: string = '';
  filterByExpired: boolean = false;
  filteredDatas: any[] = [];

  onFilterByQueryInput(event: any) {
    this.filterQuery = event.target.value;
    this.filterDatasByExpired(this.filterByExpired);
    this.filterDatasByQuery(event.target.value);
  }

  onFilterByExpiredInput(event: any) {
    this.filterByExpired = event.target.checked;
    this.filterDatasByExpired(event.target.checked);
    this.filterDatasByQuery(this.filterQuery);
  }

  filterDatasByQuery(filterQuery: string) {
    filterQuery = filterQuery.trim().toLowerCase();
    if (filterQuery === '') {
      this.filteredDatas = this.filteredDatas;
      return;
    }

    this.filteredDatas = this.filteredDatas.filter(reprocesso =>
      filterQuery &&
      (
        reprocesso['Lote']?.toLowerCase().includes(filterQuery.toLowerCase()) ||
        this.formatDate(reprocesso['Datetime de entrada do reprocesso'])?.toLowerCase().includes(filterQuery.toLowerCase()) ||
        reprocesso['Código do Produto']?.toLowerCase().includes(filterQuery.toLowerCase())
      )
    );
  }

  filterDatasByExpired(filterQuery: boolean) {
    if (!filterQuery) {
      this.filteredDatas = this.datas;
      return;
    }

    this.filteredDatas = this.datas.filter(reprocesso =>
      !reprocesso['Valido'] && reprocesso['Estoque'] > 0
    );
  }

  async getDatas() {
    this.isLoading = true;

    const data = (await axios.get(environment.apiUrl + '/reprocessos', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;

    data.forEach((item: any) => {
      let dateString = item['Datetime de entrada do reprocesso'];

      dateString = dateString.replace(/T(\d):/, 'T0$1:');

      const parsedDate = new Date(dateString);

      parsedDate.setHours(parsedDate.getHours() - 3);

      if (!isNaN(parsedDate.getTime())) {
        item['Datetime de entrada do reprocesso'] = parsedDate.toISOString().slice(0, 19);
      } else {
        console.error(`Data inválida: ${dateString}`);
      }
    });

    data.sort((a: any, b: any) =>
      new Date(b['Datetime de entrada do reprocesso']).getTime() -
      new Date(a['Datetime de entrada do reprocesso']).getTime()
    );

    this.datas = data;
    this.filteredDatas = data;

    this.isLoading = false;
  }

  showData(data: any, showMessageSucessToAddData: boolean = false) {
    data = { ...data };
    const popoverDiv = document.querySelector('#view-data') as HTMLDivElement;
    const dataKeys = Object.keys(data);

    for (let key of dataKeys) {
      if (key === 'Valido') {
        data[key] = data[key] ? 'DENTRO DO PRAZO' : 'VENCIDO';
      }
      if (key === 'Data de input' || key === 'Datetime de entrada do reprocesso' || key === 'Data de validade do reprocesso') {
        data[key] = this.formatDate(data[key]);
      }
      if (key === 'Balde 1 reprocesso armazenado' || key === 'Balde 2 reprocesso armazenado' || key === 'Balde 3 reprocesso armazenado' || key === 'Balde 4 reprocesso armazenado' || key === 'Balde 5 reprocesso armazenado' || key === 'Quantidade descartada' || key === 'Quantidade utilizada' || key === 'Total de reprocesso gerado' || key === 'Estoque') {
        data[key] = parseFloat(data[key]) / 1000;
      }

      const label = popoverDiv.getElementsByClassName(key)[0] as HTMLLabelElement;
      const input = label.querySelector(`input`) as HTMLInputElement;
      if (input) {
        input.value = data[key];
        continue;
      }
      const a = label.querySelector(`a`) as HTMLAnchorElement;
      if (a) {
        a.href = data[key] === "Não preenchido" ? "" : data[key];
        a.innerText = data[key];
        continue;
      }
      const p = label.querySelector(`p`) as HTMLParagraphElement;
      if (p) {
        p.innerText = data[key];
        continue;
      }
    }

    const sucessElement = document.querySelector('#sucess-to-add-data') as HTMLParagraphElement;
    sucessElement.innerHTML = '';

    if (showMessageSucessToAddData) sucessElement.innerHTML = 'Reprocesso adicionado/atualizado com sucesso!';

    popoverDiv.showPopover();
  }

  async submitData() {
    this.isLoading = true;
    const infoElement = document.querySelector('#info-add-data') as HTMLParagraphElement;
    infoElement.innerHTML = 'Carregando...';

    const addData: any = {};

    document.querySelectorAll('.add-data label').forEach((label) => {
      const key = label.className;
      const input = label.querySelector('input') as HTMLInputElement;
      addData[key] = input.value;
    });

    if (addData['Datetime de entrada do reprocesso']) addData['Datetime de entrada do reprocesso'] = (new Date((new Date(addData['Datetime de entrada do reprocesso'])).setHours((new Date(addData['Datetime de entrada do reprocesso'])).getHours()))).toISOString().slice(0, 19);

    for (let key in addData) if (key.startsWith('Balde')) addData[key] = parseInt(parseFloat(addData[key]) * 1000 + "", 10);

    const labels = document.querySelectorAll('.add-data label') as NodeListOf<HTMLLabelElement>;
    for (let index = 0; index < labels.length; index++) {
      const inputs = labels[index].querySelector('input') as HTMLInputElement;
      inputs.style.borderColor = '';
      const p = labels[index].querySelector('p') as HTMLParagraphElement;
      if (p) {
        p.style.color = '';
        if (p.innerText.startsWith('* Obrigatório')) {
          p.innerHTML = '* Obrigatório';
        }
      }
    }

    try {
      const response = await axios.post(environment.apiUrl + '/reprocessos', addData, { headers: { "authorization": `Bearer ${this.loginService.token}` } });
      const data = response.data;
      this.showData(data.data, true);
      this.getDatas();

      document.querySelectorAll('.add-data label').forEach((label) => {
        const input = label.querySelector('input') as HTMLInputElement;
        input.value = '';
      });

      infoElement.innerHTML = '';

    } catch (error: unknown) {
      if (!(error instanceof AxiosError)) return
      const response = error.response;
      if (response?.status === 422) {
        const responseErrors = response.data.detail;
        const divAddData = document.querySelector('.add-data') as HTMLDivElement;
        for (let responseError of responseErrors) {
          const label = divAddData.getElementsByClassName(responseError.loc[1])[0] as HTMLLabelElement;
          const input = label.querySelector('input') as HTMLInputElement;
          input.style.borderColor = '#EC3434';
          const p = label.querySelector('p') as HTMLParagraphElement;
          if (p) {
            p.style.color = '#EC3434';
            p.innerHTML += ' - ' + responseError.msg.replace('Value error, ', '');
          }
        }
        const firstLabelWithError = divAddData.getElementsByClassName(responseErrors[0].loc[1])[0] as HTMLLabelElement;
        firstLabelWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => { firstLabelWithError.focus(); }, 500);
        infoElement.innerHTML = 'Atenção! Verifique se os campo(s) estão preenchido(s) corretamente.';
      }
    }
    finally {
      this.isLoading = false;
    }
  }

  async updateData() {
    this.isLoading = true;
    const infoElement = document.querySelector('#info-update-data') as HTMLParagraphElement;
    infoElement.innerHTML = 'Carregando...';

    const updateData: any = {};

    document.querySelectorAll('.update-data label').forEach((label) => {
      const key = label.className;
      const input = label.querySelector('input') as HTMLInputElement;
      updateData[key] = input.value;
    });

    updateData['Quantidade utilizada/descartada'] = parseInt(parseFloat(updateData['Quantidade utilizada/descartada']) * 1000 + "", 10);
    updateData['Balde utilizado'] = parseInt(updateData['Balde utilizado']);

    const labels = document.querySelectorAll('.update-data label') as NodeListOf<HTMLLabelElement>;
    for (let index = 0; index < labels.length; index++) {
      const inputs = labels[index].querySelector('input') as HTMLInputElement;
      inputs.style.borderColor = '';
      const p = labels[index].querySelector('p') as HTMLParagraphElement;
      if (p) {
        p.style.color = '';
        if (p.innerText.startsWith('* Obrigatório')) {
          p.innerHTML = '* Obrigatório';
        }
      }
    }

    try {
      const response = await axios.post(environment.apiUrl + '/reprocessos/log', updateData, { headers: { "authorization": `Bearer ${this.loginService.token}` } });
      const data = response.data;
      this.showData(data.reprocesso, true);
      this.getDatas();

      document.querySelectorAll('.update-data label').forEach((label) => {
        const input = label.querySelector('input') as HTMLInputElement;
        input.value = '';
      });

      infoElement.innerHTML = '';

    } catch (error: unknown) {
      if (!(error instanceof AxiosError)) return
      const response = error.response;
      if (response?.status === 422) {
        const responseErrors = response.data.detail;
        const divUpdateData = document.querySelector('.update-data') as HTMLDivElement;
        for (let responseError of responseErrors) {
          const label = divUpdateData.getElementsByClassName(responseError.loc[1])[0] as HTMLLabelElement;
          const input = label.querySelector('input') as HTMLInputElement;
          input.style.borderColor = '#EC3434';
          const p = label.querySelector('p') as HTMLParagraphElement;
          if (p) {
            p.style.color = '#EC3434';
            p.innerHTML += ' - ' + responseError.msg.replace('Value error, ', '');
          }
        }
        const firstLabelWithError = divUpdateData.getElementsByClassName(responseErrors[0].loc[1])[0] as HTMLLabelElement;
        firstLabelWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => { firstLabelWithError.focus(); }, 500);
        infoElement.innerHTML = 'Atenção! Verifique se os campo(s) estão preenchido(s) corretamente.';
      }
      if (response?.status === 400) {
        infoElement.innerHTML = response.data.detail;
      }
    }
    finally {
      this.isLoading = false;
    }
  }

  showUpdateDataPopover(data: any) {
    const popoverDiv = document.querySelector('#update-data') as HTMLDivElement;
    const labelOfID = popoverDiv.getElementsByClassName('ID referente ao reprocesso que está sendo feita o uso ou descarte')[0] as HTMLLabelElement;
    const inputOfID = labelOfID.querySelector('input') as HTMLInputElement;
    inputOfID.value = data['Datetime de entrada do reprocesso'] + ' - ' + data['Lote'];
    popoverDiv.showPopover();
  }

  ngOnInit() {
    this.loginService.ifLoggedIn(this.initializeDatas.bind(this));
  }

  async initializeDatas() {
    this.getDatas();
    this.produtosSelect = (await axios.get(environment.apiUrl + '/produtos', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data.map((produto: any) => { return { codigo: produto['Código'], nome: produto['Produto'], grupo: produto['Grupo'] } });
  }

  formatDate(date: string) {
    try {
      return new Date(date).toLocaleString();
    }
    catch {
      return date;
    }
  }
}
