import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public token: string = '';
  private firebaseConfig = {
    apiKey: "AIzaSyBYeEfjL_dbgN-CKeyWqLNW1IunFxHmjxs",
    authDomain: "ideia-tech.firebaseapp.com",
    projectId: "ideia-tech",
    storageBucket: "ideia-tech.appspot.com",
    messagingSenderId: "818775631889",
    appId: "1:818775631889:web:231d6a249422230d5029b0"
  };

  ifLoggedIn(didLoggedIn: Function = () => { }) {
    const app = initializeApp(this.firebaseConfig);
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.token = await user.getIdToken();
        didLoggedIn();
      }
      else {
        if (window.location.pathname !== '/') window.location.href = '/?tryUrl=' + window.location.pathname.replace('/', '');
      }
    });
  }

  async login(email: string, password: string, remember: boolean) {
    const app = initializeApp(this.firebaseConfig);
    const auth = getAuth(app);
    remember ? await setPersistence(auth, browserLocalPersistence) : await setPersistence(auth, browserSessionPersistence);
    await signInWithEmailAndPassword(auth, email, password);
  }

  async logout() {
    const app = initializeApp(this.firebaseConfig);
    const auth = getAuth(app);
    await auth.signOut();
    window.location.href = '/';
  }
}
