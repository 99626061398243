import { Component } from '@angular/core';
import { LoginService } from '../login.service';
import axios from 'axios';
import { environment } from '../../environments/environment';

@Component({
  selector: 'quick-access-buttons',
  standalone: true,
  imports: [],
  templateUrl: './quick-access-buttons.component.html',
  styleUrl: './quick-access-buttons.component.css'
})
export class QuickAccessButtonsComponent {

  constructor(private loginService: LoginService) { }

  username: string = '';

  async ngOnInit() {
    this.loginService.ifLoggedIn(this.initializeDatas.bind(this));
  }

  async initializeDatas() {
    const data = (await axios.get(environment.apiUrl + '/whoami', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;
    this.username = data.nome;
  }

  logout() {
    this.loginService.logout();
  }
}
