<main>
    <h1>
        <img src="logotipo.png">
        <span>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3544_1299)">
                    <path
                        d="M27.8055 29.3505C25.0837 31.709 21.6015 33.005 18 33C9.7155 33 3 26.2845 3 18C3 9.7155 9.7155 3 18 3C26.2845 3 33 9.7155 33 18C33 21.204 31.995 24.174 30.285 26.61L25.5 18H30C29.9998 15.2343 29.0442 12.5536 27.295 10.4113C25.5458 8.26908 23.1103 6.79679 20.4005 6.24354C17.6907 5.69028 14.873 6.09003 12.424 7.37515C9.97504 8.66027 8.04513 10.7519 6.96079 13.2961C5.87644 15.8404 5.70421 18.6811 6.47324 21.3377C7.24227 23.9943 8.90534 26.3038 11.1811 27.8753C13.4569 29.4469 16.2057 30.1841 18.9625 29.9623C21.7192 29.7405 24.3148 28.5732 26.31 26.658L27.8055 29.3505Z"
                        fill="#000000" />
                </g>
                <defs>
                    <clipPath id="clip0_3544_1299">
                        <rect width="36" height="36" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            &nbsp;
            Reprocessos | Dados
        </span>
        <a href="/controle">Voltar&nbsp;&nbsp;<i class="fa-solid fa-chevron-left"></i></a>
    </h1>

    <section class="dados">

        <div>
            <p>Cadastre reprocessos aqui</p>
            <button popovertarget="add-data">Cadastrar</button>
        </div>

        <div>
            <input type="text" placeholder="Pesquisar (Por Lote, Data Reprocesso ou Produto)"
                (input)="onFilterByQueryInput($event)">
        </div>

        <div>
            <label>
                <input type="checkbox" (input)="onFilterByExpiredInput($event)"> <i
                    class="fa-solid fa-wheat-awn-circle-exclamation"></i> Mostrar apenas reprocessos vencidos e ainda em
                estoque
            </label>
        </div>

        <ul>
            <li>
                <div>
                    <span>DATA REPROCESSO</span>
                    <span>LOTE</span>
                    <span>PRODUTO</span>
                    <span>STATUS</span>
                    <span>VALIDO ATÉ</span>
                    <button disabled>MAIS INFOS <i class="fa-regular fa-circle-question"></i></button>
                    <button disabled>SINALIZAR USO/DESCARTE <i class="fa-solid fa-circle-plus"></i></button>
                </div>
            </li>
        </ul>

        <ul>
            <p *ngIf="isLoading">Carregando...</p>
            <p *ngIf="!isLoading && datas.length == 0">Nenhum dado encontrado</p>
            <li *ngFor="let data of filteredDatas">
                <div>
                    <span>{{ formatDate(data['Datetime de entrada do reprocesso']) }}</span>
                    <span>{{ data['Lote'] }}</span>
                    <span>{{ data['Código do Produto'] }}</span>
                    <span *ngIf="data['Valido'] && data['Estoque'] > 0" style="color: #11A93C;">NO PRAZO</span>
                    <span *ngIf="!data['Valido'] && data['Estoque'] > 0" style="color: #EC3434;">VENCIDO</span>
                    <span *ngIf="data['Estoque'] <= 0" style="color: #6F7480;">SEM ESTOQUE</span>
                    <span>{{ formatDate(data['Data de validade do reprocesso']) }}</span>
                    <button (click)="showData(data)">MAIS INFOS <i class="fa-regular fa-circle-question"></i></button>
                    <button (click)="showUpdateDataPopover(data)">SINALIZAR USO/DESCARTE <i class="fa-solid fa-circle-plus"></i></button>
                </div>
            </li>
        </ul>
    </section>
</main>

<div id="view-data" class="view-data" popover>

    <h2 id="sucess-to-add-data"></h2>

    <form>
        <label class="Data de input">
            <span>Data de input</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Datetime de entrada do reprocesso">
            <span>Datetime de entrada do reprocesso</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Código do Produto">
            <span>Código do Produto</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Lote">
            <span>Lote</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Balde 1 reprocesso armazenado">
            <span>Balde 1 reprocesso armazenado</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Balde 2 reprocesso armazenado">
            <span>Balde 2 reprocesso armazenado</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Balde 3 reprocesso armazenado">
            <span>Balde 3 reprocesso armazenado</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Balde 4 reprocesso armazenado">
            <span>Balde 4 reprocesso armazenado</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Balde 5 reprocesso armazenado">
            <span>Balde 5 reprocesso armazenado</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Quantidade descartada">
            <span>Quantidade descartada</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Quantidade utilizada">
            <span>Quantidade utilizada</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Total de reprocesso gerado">
            <span>Total de reprocesso gerado</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Estoque">
            <span>Estoque</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Reprocessos permitidos">
            <span>Reprocessos permitidos</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Data de validade do reprocesso">
            <span>Data de validade do reprocesso</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Valido">
            <span>Valido</span>
            <input type="text" value="" disabled>
        </label>

    </form>

    <div class="buttons">
        <button class="outlined" popoverTarget="view-data">Fechar</button>
    </div>
</div>

<div id="add-data" class="add-data" popover>

    <form>

        <label class="Datetime de entrada do reprocesso">
            <span>Datetime de entrada do reprocesso</span>
            <input type="date" value="">
            <p>* Obrigatório</p>
        </label>

        <label class="Código do Produto">
            <span>Código do Produto</span>
            <input list="produto_sugestoes" placeholder="Código do produto">
            <datalist id="produto_sugestoes">
                <option *ngFor="let produto of produtosSelect" value="{{ produto.codigo }}">{{ produto.codigo }} ({{
                    produto.nome }})</option>
            </datalist>
            <p>* Obrigatório</p>
        </label>

        <label class="Lote">
            <span>Lote</span>
            <input type="text" placeholder="Lote do reprocesso gerado">
            <p>* Obrigatório</p>
        </label>

        <label class="Balde 1 reprocesso armazenado">
            <span>Balde 1 reprocesso armazenado</span>
            <input type="number" placeholder="Em quilogramas (Max. 3 casas decimais)">
            <p>* Obrigatório</p>
        </label>

        <label class="Balde 2 reprocesso armazenado">
            <span>Balde 2 reprocesso armazenado</span>
            <input type="number" placeholder="Em quilogramas (Max. 3 casas decimais)">
            <p>* Obrigatório</p>
        </label>

        <label class="Balde 3 reprocesso armazenado">
            <span>Balde 3 reprocesso armazenado</span>
            <input type="number" placeholder="Em quilogramas (Max. 3 casas decimais)">
            <p>* Obrigatório</p>
        </label>

        <label class="Balde 4 reprocesso armazenado">
            <span>Balde 4 reprocesso armazenado</span>
            <input type="number" placeholder="Em quilogramas (Max. 3 casas decimais)">
            <p>* Obrigatório</p>
        </label>

        <label class="Balde 5 reprocesso armazenado">
            <span>Balde 5 reprocesso armazenado</span>
            <input type="number" placeholder="Em quilogramas (Max. 3 casas decimais)">
            <p>* Obrigatório</p>
        </label>

    </form>

    <p id="info-add-data" class="black"></p>

    <div class="buttons">
        <button class="" (click)="submitData()" [disabled]="isLoading">Cadastrar</button>
        <button class="outlined" popoverTarget="add-data" [disabled]="isLoading">Fechar</button>
    </div>
</div>

<div id="update-data" class="update-data" popover>

    <form>

        <label class="ID referente ao reprocesso que está sendo feita o uso ou descarte">
            <span>ID referente ao reprocesso que está sendo feita o uso ou descarte</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Modo de uso ou descarte">
            <span>Modo de uso ou descarte</span>
            <input list="modo_sugestoes" placeholder="Modo de uso ou descarte">
            <datalist id="modo_sugestoes">
                <option value="uso">Uso</option>
                <option value="descarte">Descarte</option>
            </datalist>
            <p>* Obrigatório</p>
        </label>

        <label class="Quantidade utilizada/descartada">
            <span>Quantidade utilizada/descartada</span>
            <input type="number" placeholder="Em quilogramas">
            <p>* Obrigatório</p>
        </label>

        <label class="Justificativa do descarte (caso haja)">
            <span>Justificativa do descarte (caso haja)</span>
            <input type="text" placeholder="Justificativa do descarte">
            <p></p>
        </label>

        <label class="Balde utilizado">
            <span>Balde utilizado</span>
            <input type="number" placeholder="Selecione o balde utilizado" list="balde_sugestoes">
            <datalist id="balde_sugestoes">
                <option value="1">Balde 1</option>
                <option value="2">Balde 2</option>
                <option value="3">Balde 3</option>
                <option value="4">Balde 4</option>
                <option value="5">Balde 5</option>
            </datalist>
            <p>* Obrigatório</p>
        </label>

    </form>

    <p id="info-update-data" class="black"></p>

    <div class="buttons">
        <button class="" (click)="updateData()" [disabled]="isLoading">Sinalizar</button>
        <button class="outlined" popoverTarget="update-data" [disabled]="isLoading">Fechar</button>
    </div>
</div>