import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ControleComponent } from './controle/controle.component';
import { PerfilComponent } from './perfil/perfil.component';
import { ControleDadosComponent } from './controle-dados/controle-dados.component';
import { ReprocessosDadosComponent } from './reprocessos-dados/reprocessos-dados.component';

export const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'home', component: ControleComponent },
    { path: 'controle-qualidade', component: ControleDadosComponent },
    { path: 'reprocessos', component: ReprocessosDadosComponent },
    { path: 'perfil', component: PerfilComponent },
    { path: '**', redirectTo: 'home' }
];
