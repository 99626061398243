import { Component } from '@angular/core';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { LoadingComponent } from "../loading/loading.component";
import { CommonModule } from '@angular/common';
import { LoginService } from '../login.service';
import { Title } from '@angular/platform-browser';
import { QuickAccessButtonsComponent } from "../quick-access-buttons/quick-access-buttons.component";

@Component({
  selector: 'perfil',
  standalone: true,
  imports: [LoadingComponent, CommonModule, QuickAccessButtonsComponent],
  templateUrl: './perfil.component.html',
  styleUrl: './perfil.component.css'
})
export class PerfilComponent {

  constructor(private title: Title, private loginService: LoginService) { 
    this.title.setTitle('Minha Conta - Predicta');
  }

  isLoading: boolean = true;
  users: any[] = [];
  modules: any[] = [];

  ngOnInit() {
    this.loginService.ifLoggedIn(this.initializeDatas.bind(this));
    this.setOnChangeName();
  }

  async getUsers(currentUser: any) {
    const adminPanel = document.querySelector('#admin-panel') as HTMLElement;
    adminPanel.style.display = 'none';
    if (!currentUser.admin) return;
    adminPanel.style.display = 'flex';
    const users = (await axios.get(environment.apiUrl + '/usuarios', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;
    this.users = users;
  }

  async getModules(currentUser: any) {
    if (!currentUser.admin) return;
    const modules = (await axios.get(environment.apiUrl + '/modulos', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;
    this.modules = modules;
  }

  async createUser() {
    const nome = (document.querySelector('#nome-create') as HTMLInputElement).value;
    const email = (document.querySelector('#email-create') as HTMLInputElement).value;
    const password = (document.querySelector('#senha-create') as HTMLInputElement).value;
    const admin = (document.querySelector('#admin-create') as HTMLInputElement).checked;
    const modules = Array.from(document.querySelectorAll('#acessos-create input:checked') as NodeListOf<HTMLInputElement>).map(input => input.id);

    if (!nome || !email || !password) {
      const infoElement = document.querySelector('#info-create') as HTMLElement;
      infoElement.innerHTML = '<i class="fa-solid fa-triangle-exclamation"></i> Preencha todos os campos';
      return;
    }

    const requestBody = { "name": nome, "email": email, "password": password, "accesses": modules, "admin": admin };

    const button = document.querySelector('#criar') as HTMLButtonElement;

    try {
      this.isLoading = true;
      button.disabled = true;
      await axios.post(environment.apiUrl + '/usuarios', requestBody, { headers: { "authorization": `Bearer ${this.loginService.token}` } });
      (document.querySelector('#user-create') as HTMLElement).togglePopover();
      this.getProfileInfo();
      this.getUsers({ admin: true });
    }
    catch (error) {
      const infoElement = document.querySelector('#info-create') as HTMLElement;
      infoElement.innerHTML = '<i class="fa-solid fa-triangle-exclamation"></i> Erro ao criar usuário';
    }
    finally {
      this.isLoading = false;
      button.disabled = false;
    }
  }

  async editUser() {
    this.isLoading = true;
    const button = document.querySelector('#editar') as HTMLButtonElement;
    button.disabled = true;
    const modules = Array.from(document.querySelectorAll('#acessos-edit input:checked') as NodeListOf<HTMLInputElement>).map(input => input.id);
    const email = (document.querySelector('#email-edit') as HTMLInputElement).value;
    const admin = (document.querySelector('#admin-edit') as HTMLInputElement).checked;
    const ativo = (document.querySelector('#ativado-edit') as HTMLInputElement).checked;
    const requestBody = { "accesses": modules, "admin": admin, "ativo": ativo };

    try {
      await axios.put(environment.apiUrl + '/usuarios/' + encodeURIComponent(email), requestBody, { headers: { "authorization": `Bearer ${this.loginService.token}` } });
      (document.querySelector('#user-edit') as HTMLElement).togglePopover();
      this.getProfileInfo();
      this.getUsers({ admin: true });
    }
    catch (error) {
      const infoElement = document.querySelector('#info-edit') as HTMLElement;
      infoElement.innerHTML = '<i class="fa-solid fa-triangle-exclamation"></i> Erro ao editar usuário';
    }
    finally {
      this.isLoading = false;
      button.disabled = false;
    }
  }

  openEditUser(user: any) {
    const popoverEdit = document.querySelector('#user-edit') as HTMLElement;
    popoverEdit.querySelector('p#info-edit')!.innerHTML = '';
    const nomeEdit = document.querySelector('#nome-edit') as HTMLInputElement;
    nomeEdit.value = user.nome;
    const emailEdit = document.querySelector('#email-edit') as HTMLInputElement;
    emailEdit.value = user.email;
    const acessosEdit = document.querySelector('#acessos-edit') as HTMLUListElement;
    acessosEdit.childNodes.forEach((li: HTMLLIElement | any) => {
      const label = li.querySelector('label') as HTMLLabelElement;
      const input = label.querySelector('input') as HTMLInputElement;
      if (user.acessos.find((acesso: string) => acesso === input.id)) {
        input.checked = true;
      }
    });
    const adminEdit = document.querySelector('#admin-edit') as HTMLInputElement;
    adminEdit.checked = user.admin;
    const ativadoEdit = document.querySelector('#ativado-edit') as HTMLInputElement;
    ativadoEdit.checked = user.ativo;
    popoverEdit.togglePopover();
  }

  setOnChangeName() {
    const nomeElement = document.querySelector('#nome') as HTMLInputElement;
    nomeElement.oninput = async () => {
      const button = document.querySelector('#alterar') as HTMLButtonElement;
      button.disabled = false;
      button.onclick = async () => {
        button.disabled = true;
        this.isLoading = true;
        const nome = nomeElement.value;
        await axios.put(environment.apiUrl + '/whoami', { "name": nome }, { headers: { "authorization": `Bearer ${this.loginService.token}` } });
        this.getProfileInfo();
        this.getUsers({ admin: true });
      };
    }
  }

  async getProfileInfo() {
    const data = (await axios.get(environment.apiUrl + '/whoami', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;
    const nome = data.nome;
    const email = data.email;
    const acessos = data.acessos;

    const nomeElement = document.querySelector('#nome') as HTMLInputElement;
    nomeElement.value = nome;
    const emailElement = document.querySelector('#email') as HTMLInputElement;
    emailElement.value = email;
    const acessosElement = document.querySelector('#acessos') as HTMLUListElement;
    acessosElement.innerHTML = '';
    acessos.forEach((acesso: string) => {
      const li = document.createElement('li');
      li.textContent = acesso;
      acessosElement.appendChild(li);
    });

    this.isLoading = false;

    return data;
  }

  async initializeDatas() {
    const data = await this.getProfileInfo();
    this.getUsers(data);
    this.getModules(data);
  }
}
