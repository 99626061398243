<main>
    <img src="logotipo.png">
    <h2>Faça o Login</h2>
    <h3>E tenha acesso ao dashboard</h3>
    <form [formGroup]="loginForm">
        <label>
            Insira o e-mail <input type="email" placeholder="E-mail" formControlName="email" />
            <p *ngIf="loginForm.get('email')?.hasError('email') && loginForm.get('email')?.touched">
                <i class="fa-solid fa-triangle-exclamation"></i> E-mail inválido
            </p>
            <p *ngIf="loginForm.get('email')?.hasError('required') && loginForm.get('email')?.touched">
                <i class="fa-solid fa-triangle-exclamation"></i> E-mail é necessário
            </p>
        </label>
        <label>
            Insira a senha <input type="password" placeholder="Senha" formControlName="password" />
            <p *ngIf="loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched">
                <i class="fa-solid fa-triangle-exclamation"></i> Senha é necessária
            </p>
        </label>
        <label class="checkbox">
            <input type="checkbox" formControlName="remember" /> <span><i class="fa-solid fa-fingerprint"></i> Lembrar-me no próximo acesso.</span> 
        </label>
        <button (click)="login()" [disabled]="isLoading">Entrar</button>
    </form>
    <p *ngIf="warning">{{warning}}</p>
    <div>
        Não tem acesso ao dashboard, mas acredita que deveria? Entre em contato com o administrador do sistema para solicitar acesso.
    </div>
</main>

<loading [isLoading]="isLoading"></loading>