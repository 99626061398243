import { Component } from '@angular/core';
import { LoadingComponent } from "../loading/loading.component";
import { CommonModule } from '@angular/common';
import axios, { AxiosError } from 'axios';
import { environment } from '../../environments/environment';
import { LoginService } from '../login.service';
import { Title } from '@angular/platform-browser';
import { QuickAccessButtonsComponent } from "../quick-access-buttons/quick-access-buttons.component";

@Component({
  selector: 'controle-dados',
  standalone: true,
  imports: [LoadingComponent, CommonModule, QuickAccessButtonsComponent],
  templateUrl: './controle-dados.component.html',
  styleUrl: './controle-dados.component.css'
})
export class ControleDadosComponent {

  constructor(private title: Title, private loginService: LoginService) { 
    this.title.setTitle('Controle Qualidade - Predicta');
  }

  isLoading = true;

  datas = [];
  filteredDatas = [];
  drafts = [];
  produtosSelect: any[] = [];
  categoriasSelect = [];
  embalagensSelect = [];
  equipamentosSelect = [];
  addData: any = {
    "Datetime de início de preenchimento": null,
    "Email": null,
    "Nome do(a) responsável": null,
    "Produto": null,
    "LOTE": null,
    "Aparência": null,
    "Odor": null,
    "Sabor": null,
    "Textura": null,
    "Houve alguma NÃO CONFORMIDADE na análise sensorial?": null,
    "Qual a causa da não conformidade?": null,
    "Fotos em caso de não conformidade visual.": null,
    "Categoria": null,
    "Passa pela laminação?": null,
    "Horário - Do produto acabado": null,
    "Carrinho": null,
    "Range de Peso da Placa - Mínimo (em gramas)": null,
    "Range de Peso da Placa - Máximo (em gramas)": null,
    "Peso da Placa 1 (em gramas)": null,
    "Peso da Placa 2 (em gramas)": null,
    "Range de Peso do Produto - Mínimo (em gramas)": null,
    "Range de Peso do Produto - Máximo (em gramas)": null,
    "AMOSTRA 1": null,
    "AMOSTRA 2": null,
    "AMOSTRA 3": null,
    "AMOSTRA 4": null,
    "AMOSTRA 5": null,
    "AMOSTRA 6": null,
    "AMOSTRA 7": null,
    "AMOSTRA 8": null,
    "AMOSTRA 9": null,
    "AMOSTRA 10": null,
    "O produto é Pinati?": null,
    "AMOSTRA 11": null,
    "AMOSTRA 12": null,
    "AMOSTRA 13": null,
    "AMOSTRA 14": null,
    "AMOSTRA 15": null,
    "O produto possui cobertura de chocolate?": null,
    "PRODUTO FINAL - AMOSTRA 1": null,
    "PRODUTO FINAL - AMOSTRA 2": null,
    "PRODUTO FINAL - AMOSTRA 3": null,
    "PRODUTO FINAL - AMOSTRA 4": null,
    "PRODUTO FINAL - AMOSTRA 5": null,
    "PRODUTO FINAL - AMOSTRA 6": null,
    "PRODUTO FINAL - AMOSTRA 7": null,
    "PRODUTO FINAL - AMOSTRA 8": null,
    "PRODUTO FINAL - AMOSTRA 9": null,
    "PRODUTO FINAL - AMOSTRA 10": null,
    "A etiqueta está conforme?": null,
    "Foto da etiqueta": null,
    "VALIDADE": null,
    "EMBALAGEM": null,
    "EQUIPAMENTO": null,
    "Compatibilidade de lote e validade": null,
    "Horário - De envio": null,
    "Itens conformes": null,
    "Houve algum item não conforme?": null,
    "DE ACORDO?": null,
    "Documento verificado e liberado pelo responsável?": null
  };

  async getDatas() {
    this.isLoading = true;

    const data = (await axios.get(environment.apiUrl + '/dados/unprocessed', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;

    data.forEach((item: any) => {
      let dateString = item['Datetime de início de preenchimento'];

      dateString = dateString.replace(/T(\d):/, 'T0$1:');

      const parsedDate = new Date(dateString);

      parsedDate.setHours(parsedDate.getHours() - 3);

      if (!isNaN(parsedDate.getTime())) {
        item['Datetime de início de preenchimento'] = parsedDate.toISOString().slice(0, 19);
      } else {
        console.error(`Data inválida: ${dateString}`);
      }
    });
    
    data.sort((a: any, b: any) => 
      new Date(b['Datetime de início de preenchimento']).getTime() - 
      new Date(a['Datetime de início de preenchimento']).getTime()
    );

    this.datas = data;

    this.filteredDatas = this.datas;

    this.isLoading = false;
  }

  async getDrafts() {
    this.isLoading = true;

    const data = (await axios.get(environment.apiUrl + '/rascunhos', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;

    data.forEach((item: any) => {
      let dateString = item['Datetime de início de preenchimento'];

      dateString = dateString.replace(/T(\d):/, 'T0$1:');

      const parsedDate = new Date(dateString);

      parsedDate.setHours(parsedDate.getHours() - 3);
    
      if (!isNaN(parsedDate.getTime())) {
        item['Datetime de início de preenchimento'] = parsedDate.toISOString().slice(0, 19);
      } else {
        console.error(`Data inválida: ${dateString}`);
      }
    });

    this.drafts = data;

    this.isLoading = false;
  }

  onSearchChange(seachquery: Event) {
    const searchquery = (seachquery.target as HTMLInputElement).value || '';
    if (searchquery === '') {
      this.filteredDatas = this.datas;
      return;
    }
    this.filteredDatas = this.datas.filter((data: any) => data['LOTE'].includes(searchquery) || (new Date(data['Datetime de início de preenchimento'])).toLocaleString().includes(searchquery));
    return;
  }

  showData(data: any, showMessageSucessToAddData: boolean = false) {
    const popoverDiv = document.querySelector('#view-data') as HTMLDivElement;

    data = this.formatData({ ...data });

    const dataKeys = Object.keys(data);

    for (let key of dataKeys) {
      const label = popoverDiv.getElementsByClassName(key)[0] as HTMLLabelElement;
      const input = label.querySelector(`input`) as HTMLInputElement;
      if (input) {
        input.value = data[key];
        continue;
      }
      const a = label.querySelector(`a`) as HTMLAnchorElement;
      if (a) {
        a.href = data[key] === "Não preenchido" ? "" : data[key];
        a.innerText = data[key];
        continue;
      }
      const p = label.querySelector(`p`) as HTMLParagraphElement;
      if (p) {
        p.innerText = data[key];
        continue;
      }
    }

    const sucessElement = document.querySelector('#sucess-to-add-data') as HTMLParagraphElement;
    sucessElement.innerHTML = '';

    if (showMessageSucessToAddData) sucessElement.innerHTML = 'Dados adicionados com sucesso!';

    popoverDiv.showPopover();
  }

  openAddDataPopover() {
    this.clearAddDataForm();
    const currentDateTimeIsoDate = new Date(
      new Date().getTime() - 3 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 19); 
    this.addData['Datetime de início de preenchimento'] = currentDateTimeIsoDate.replace('Z', '');
    const addDataPopover = document.querySelector('#add-data') as HTMLDivElement;
    addDataPopover.showPopover();

    this.saveDraft();
  }

  openAddDataPopoverDraft(data: any) {
    this.clearAddDataForm();
    const popoverDiv = document.querySelector('#add-data') as HTMLDivElement;

    const dataKeys = Object.keys(this.addData);

    for (let key of dataKeys) {
      this.addData[key] = data[key];
    }

    for (let key of dataKeys) {
      if ('Itens conformes' === key) {
        const itensConformes = data[key] ? data[key].split(', ') : [];
        const allCheckboxes = (document.querySelectorAll('#itens-conformes input') as NodeListOf<HTMLInputElement>);
        for (let index = 0; index < allCheckboxes.length; index++) {
          const checkbox = allCheckboxes[index];
          checkbox.checked = itensConformes.includes(checkbox.className);
        }
        continue;
      }
      if ('Horário - Do produto acabado' === key) {
        const date = new Date(data[key]);
        const time = date.toLocaleTimeString().split(':');
        const timeString = time[0] + ':' + time[1];
        const input = popoverDiv.getElementsByClassName(key)[0].querySelector('input') as HTMLInputElement;
        input.value = timeString;
        continue;
      }
      if ('VALIDADE' === key) {
        const date = new Date(data[key]);
        const dateString = date.toISOString().split('T')[0];
        const input = popoverDiv.getElementsByClassName(key)[0].querySelector('input') as HTMLInputElement;
        input.value = dateString;
        continue;
      }
      const label = popoverDiv.getElementsByClassName(key)[0] as HTMLLabelElement;
      if (!label) continue;
      const input = label.querySelector(`input`) as HTMLInputElement;
      if (input) {
        if (input.type === 'file') {
          const a = document.createElement('a');
          a.href = this.addData[key];
          a.innerText = this.addData[key];
          const p = label.querySelector(`p:last-child`) as HTMLParagraphElement;
          p.innerHTML = 'Escolhido anteriormente: ';
          p.appendChild(a);
          p.innerHTML += '. Caso deseje alterar, selecione um novo arquivo acima.';
          continue;
        }
        input.value = this.addData[key];
        continue;
      }
      const select = label.querySelector(`select`) as HTMLSelectElement;
      select.value = this.addData[key];
    }

    popoverDiv.showPopover();
  }

  async submitData() {
    this.isLoading = true;
    const infoElement = document.querySelector('#info-add-data') as HTMLParagraphElement;
    infoElement.innerHTML = 'Carregando...';

    this.addData['Horário - De envio'] = new Date().toLocaleString('sv-SE', { timeZoneName: 'short' }).replace(' ', 'T').split(' ')[0];

    if (this.addData['Fotos em caso de não conformidade visual.'] === "None") this.addData['Fotos em caso de não conformidade visual.'] = null;
    if (this.addData['Foto da etiqueta'] === "None") this.addData['Foto da etiqueta'] = null;

    const labels = document.querySelectorAll('.add-data label') as NodeListOf<HTMLLabelElement>;
    for (let index = 0; index < labels.length; index++) {
      const selectOrInput = labels[index].querySelector('select, input') as HTMLSelectElement | HTMLInputElement;
      selectOrInput.style.borderColor = '';
      const p = labels[index].querySelector('p') as HTMLParagraphElement;
      if (p) {
        p.style.color = '';
        if (p.innerText.startsWith('* Obrigatório')) {
          p.innerHTML = '* Obrigatório';
        }
      }
    }

    try {
      const response = await axios.post(environment.apiUrl + '/dados', this.addData, { headers: { "authorization": `Bearer ${this.loginService.token}` } });
      const data = response.data;
      this.showData(data.data, true);
      this.getDatas();
      await axios.delete(environment.apiUrl + '/rascunhos/' + data.data['Datetime de início de preenchimento'] + ' - ' + data.data['Email'], { headers: { "authorization": `Bearer ${this.loginService.token}` } });
      this.getDrafts();
    } catch (error: unknown) {
      if (!(error instanceof AxiosError)) return
      const response = error.response;
      if (response?.status === 422) {
        const responseErrors = response.data.detail;
        const divAddData = document.querySelector('.add-data') as HTMLDivElement;
        for (let responseError of responseErrors) {
          const label = divAddData.getElementsByClassName(responseError.loc[1])[0] as HTMLLabelElement;
          const selectOrInput = label.querySelector('select, input') as HTMLSelectElement | HTMLInputElement;
          selectOrInput.style.borderColor = '#EC3434';
          const p = label.querySelector('p') as HTMLParagraphElement;
          if (p) {
            p.style.color = '#EC3434';
            p.innerHTML += ' - ' + responseError.msg.replace('Value error, ', '');
          }
        }
        const firstLabelWithError = divAddData.getElementsByClassName(responseErrors[0].loc[1])[0] as HTMLLabelElement;
        firstLabelWithError.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => { firstLabelWithError.focus(); }, 500);
        infoElement.innerHTML = 'Atenção! Verifique se os campo(s) estão preenchido(s) corretamente.';
      }
    }
    finally {
      this.isLoading = false;
    }
  }

  async saveDraft() {
    const addDataPopover = document.querySelector('#add-data') as HTMLDivElement;
    const p = addDataPopover.querySelector('p:first-child') as HTMLParagraphElement;

    p.innerHTML = '<i class="fa-solid fa-spinner"></i> Salvando rascunho...';

    try {
      await axios.post(environment.apiUrl + '/rascunhos', this.addData, { headers: { "authorization": `Bearer ${this.loginService.token}` } });
      this.getDrafts();
      p.innerHTML = '<i class="fa-brands fa-firstdraft"></i> Rascunho salvo com sucesso!';
    } catch (error: unknown) {
      console.log(error);
      p.innerHTML = '<i class="fa-solid fa-triangle-exclamation"></i> Erro ao salvar rascunho!';
    }
  }

  clearAddDataForm() {
    const allInputs = document.querySelectorAll('.add-data input') as NodeListOf<HTMLInputElement>;
    for (let index = 0; index < allInputs.length; index++) {
      allInputs[index].value = '';
      allInputs[index].checked = false;
      allInputs[index].style.borderColor = '';
      const p = allInputs[index].parentElement?.querySelector('p');
      if (p) {
        p.style.color = '';
        if (p.innerText.startsWith('* Obrigatório')) {
          p.innerHTML = '* Obrigatório';
        }
      }
    }
    const allSelects = document.querySelectorAll('.add-data select') as NodeListOf<HTMLSelectElement>;
    for (let index = 0; index < allSelects.length; index++) {
      allSelects[index].value = '';
      allSelects[index].style.borderColor = '';
      const p = allSelects[index].parentElement?.querySelector('p');
      if (p) {
        p.style.color = '';
        if (p.innerText.startsWith('* Obrigatório')) {
          p.innerHTML = '* Obrigatório';
        }
      }
    }
    const keys = Object.keys(this.addData);
    for (let key of keys) this.addData[key] = null;
    const infoElement = document.querySelector('#info-add-data') as HTMLParagraphElement;
    infoElement.innerHTML = '';
    const allP = document.querySelectorAll('.add-data p') as NodeListOf<HTMLParagraphElement>;
    for (let index = 0; index < allP.length; index++) {
      if (allP[index].innerText === '* Obrigatório') continue;
      if (allP[index].classList.contains('keep')) continue;
      allP[index].innerHTML = '';
    }
  }

  formatData(row: any) {
    row['Aparência'] = row['Aparência'] === true && row['Aparência'] !== null ? 'Conforme' : 'Não conforme';
    row['Odor'] = row['Odor'] === true && row['Odor'] !== null ? 'Conforme' : 'Não conforme';
    row['Sabor'] = row['Sabor'] === true && row['Sabor'] !== null ? 'Conforme' : 'Não conforme';
    row['Textura'] = row['Textura'] === true && row['Textura'] !== null ? 'Conforme' : 'Não conforme';

    row['Houve alguma NÃO CONFORMIDADE na análise sensorial?'] = row['Houve alguma NÃO CONFORMIDADE na análise sensorial?'] === true && row['Houve alguma NÃO CONFORMIDADE na análise sensorial?'] !== null ? 'Sim' : 'Não';

    row['Passa pela laminação?'] = row['Passa pela laminação?'] === true && row['Passa pela laminação?'] !== null ? 'Sim' : 'Não';

    row['O produto é Pinati?'] = row['O produto é Pinati?'] === true && row['O produto é Pinati?'] !== null ? 'Sim' : 'Não';

    row['O produto possui cobertura de chocolate?'] = row['O produto possui cobertura de chocolate?'] === true && row['O produto possui cobertura de chocolate?'] !== null ? 'Sim' : 'Não';

    row['A etiqueta está conforme?'] = row['A etiqueta está conforme?'] === true && row['A etiqueta está conforme?'] !== null ? 'Conforme' : 'Não conforme';

    row['Compatibilidade de lote e validade'] = row['Compatibilidade de lote e validade'] === true && row['Compatibilidade de lote e validade'] !== null ? 'Conforme' : 'Não conforme';

    row['Houve algum item não conforme?'] = row['Houve algum item não conforme?'] === true && row['Houve algum item não conforme?'] !== null ? 'Sim' : 'Não';

    row['DE ACORDO?'] = row['DE ACORDO?'] === true && row['DE ACORDO?'] !== null ? 'De acordo' : 'Não de acordo';

    row['Datetime de início de preenchimento'] = row['Datetime de início de preenchimento'] ? new Date(row['Datetime de início de preenchimento']).toLocaleString() : "Não preenchido";

    row['Horário - Do produto acabado'] = row['Horário - Do produto acabado'] ? new Date(row['Horário - Do produto acabado']).toLocaleString() : "Não preenchido";

    row['VALIDADE'] = row['VALIDADE'] ? new Date(row['VALIDADE']).toLocaleString() : "Não preenchido";

    row['Horário - De envio'] = row['Horário - De envio'] ? new Date(row['Horário - De envio']).toLocaleString() : "Não preenchido";

    const keys = Object.keys(row);

    for (let key of keys) row[key] = row[key] === null ? "Não preenchido" : row[key];

    for (let key of keys) row[key] = typeof row[key] === 'string' ? row[key].replaceAll('$BARRA$', '/') : row[key];

    return row;
  }

  async populatesSelects() {
    this.produtosSelect = (await axios.get(environment.apiUrl + '/produtos', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data.map((produto: any) => { return { codigo: produto['Código'], nome: produto['Produto'], grupo: produto['Grupo'] } });
    this.categoriasSelect = (await axios.get(environment.apiUrl + '/categorias', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;
    this.embalagensSelect = (await axios.get(environment.apiUrl + '/embalagens', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;
    this.equipamentosSelect = (await axios.get(environment.apiUrl + '/equipamentos', { headers: { "authorization": `Bearer ${this.loginService.token}` } })).data;
  }

  showWarning(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    if (selectElement.value === 'true') {
      const warning = document.querySelector('#reportar-qualidade-warning') as HTMLDivElement;
      warning.showPopover();
    }
    this.onInputChange(event);
  }

  closeWarning() {
    const warning = document.querySelector('#reportar-qualidade-warning') as HTMLDivElement;
    warning.hidePopover();
    const addDataPopover = document.querySelector('#add-data') as HTMLDivElement;
    addDataPopover.showPopover();
  }

  onInputChange(event: Event) {
    const inputElement = event.target as any;
    const key = inputElement.parentElement?.className || '';
    const value = inputElement.value || '';
    this.addData[key] = value;
    if (value === 'true' || value === 'false') this.addData[key] = value === 'true';
    if (inputElement.type === 'time') {
      const date = new Date();
      const time = inputElement.value.split(':');
      date.setHours(parseInt(time[0]), parseInt(time[1]));
      date.setHours(date.getHours() - 3);
      this.addData[key] = date.toISOString().slice(0, 19);
    }
    if (inputElement.type === 'date') {
      const date = new Date(inputElement.value);
      this.addData[key] = date.toISOString().slice(0, 19);
    }
    if (inputElement.type === 'number' && key !== "Carrinho") this.addData[key] = parseFloat(value);

    this.saveDraft();
  }

  async onInputFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) return;

    const file = input.files[0];

    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(environment.apiUrl + '/arquivos', formData, { headers: { "authorization": `Bearer ${this.loginService.token}`, "Content-Type": "multipart/form-data" } });
    const data = response.data;
    const publicUrl = data['public_url'];

    const key = input.parentElement?.className || '';
    this.addData[key] = publicUrl;

    this.saveDraft();
  }

  onInputItensConformesChange(event: Event) {
    const key = "Itens conformes"
    const allCheckboxes = (document.querySelectorAll('#itens-conformes input') as NodeListOf<HTMLInputElement>);
    this.addData[key] = '';
    for (let index = 0; index < allCheckboxes.length; index++) {
      const checkbox = allCheckboxes[index];
      if (checkbox.checked) this.addData[key] += `${checkbox.className}, `;
    }
    this.addData[key] = this.addData[key].slice(0, -2);

    this.saveDraft();
  }

  toPrintData() {
    const viewData = document.querySelector('#view-data form') as HTMLFormElement;

    const cloneForm = viewData.cloneNode(true) as HTMLFormElement;

    const inputs = viewData.querySelectorAll('input, a') as NodeListOf<HTMLInputElement | HTMLAnchorElement>;
    const cloneInputs = cloneForm.querySelectorAll('input, a') as NodeListOf<HTMLInputElement | HTMLAnchorElement>;

    inputs.forEach((input, index) => {
      if (cloneInputs[index]) {
        const p = document.createElement('p');
        p.textContent = (input instanceof HTMLInputElement) ? input.value : input.innerText;

        cloneInputs[index].replaceWith(p);
      }
    });

    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(`
            <html>
            <head>
                <title>Impressão</title>
                <style>
                    @import "@fortawesome/fontawesome-free/css/all.css";
                    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

                    body {
                        font-family: "Poppins", sans-serif;
                        margin: 20px;
                    }
                    p {
                        margin: 10px 0;
                        font-size: 12px;
                        font-weight: 400;
                    }
                    label {
                        font-weight: 500;,
                        font-size: 14px;
                    }
                </style>
            </head>
            <body>
                ${cloneForm.outerHTML}
            </body>
            </html>
        `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    }
  }

  ngOnInit() {
    this.loginService.ifLoggedIn(this.initializeDatas.bind(this));
    this.settingsLabels();
  }

  async initializeDatas() {
    await this.getDatas();
    this.getDrafts();
    this.populatesSelects();
  }

  settingsLabels() {const allLabelsAddData = document.querySelectorAll('.add-data label') as NodeListOf<HTMLLabelElement>;
    for (let index = 0; index < allLabelsAddData.length; index++) {
      const label = allLabelsAddData[index];
      const selectOrInput = label.querySelector('select, input') as HTMLSelectElement | HTMLInputElement;
      selectOrInput.addEventListener('input', (e) => {
        const element = e.target as HTMLSelectElement | HTMLInputElement;
        if (element) element.style.borderColor = '';
        const p = label.querySelector('p') as HTMLParagraphElement;
        if (p) {
          p.style.color = '';
          if (p.innerText.startsWith('* Obrigatório')) {
            p.innerHTML = '* Obrigatório';
          }
        }
      });
    }
  }

  formatDate(date: string) {
    try {
      return new Date(date).toLocaleString();
    }
    catch {
      return date;
    }
  }
}
