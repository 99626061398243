import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingComponent } from '../loading/loading.component';
import { LoginService } from '../login.service';

@Component({
  selector: 'login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, LoadingComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  isLoading = false;

  constructor(private title: Title, private loginService: LoginService) {
    this.title.setTitle('Login - Predicta');
  }

  loginForm = new FormGroup({
    email: new FormControl({value: '', disabled: this.isLoading}, [Validators.required, Validators.email]),
    password: new FormControl({value: '', disabled: this.isLoading}, [Validators.required]),
    remember: new FormControl({value: true, disabled: this.isLoading})
  });
  warning: string = '';

  ngOnInit() {
    this.loginService.ifLoggedIn(this.redirectTo.bind(this));
    this.settingsLabels();
  }

  settingsLabels() {
    (document.querySelector('main>form>label') as HTMLElement).focus();
    const labels: HTMLLabelElement[] = document.querySelectorAll('main>form>label') as any;
    labels.forEach((label, index) => {
      label.addEventListener('keydown', (event: any) => {
        if (event.key === 'Enter') (labels[index].nextElementSibling as HTMLElement).focus();
      });
    });
  }

  redirectTo() {
    const redirectToPage = new URLSearchParams(window.location.search).get('tryUrl') || '';
    window.location.href = redirectToPage ? '/' + redirectToPage : '/controle';
  }

  async login() {
    this.warning = '';

    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const email = this.loginForm.get('email')?.value || '';
    const password = this.loginForm.get('password')?.value || '';
    const remember = this.loginForm.get('remember')?.value || false;

    try {
      await this.loginService.login(email, password, remember);
    } catch (error) {
      this.warning = "Login ou senha inválidos.";
    } finally {
      this.isLoading = false;
    }
  }

}
