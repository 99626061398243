<section>
    <div [attr.aria-disabled]="isLoading">
        <div>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.5 0.8145C16.995 0.771 17.4945 0.75 18 0.75C27.5265 0.75 35.25 8.4735 35.25 18C35.25 18.5055 35.229 19.005 35.1855 19.5H32.9265C32.1735 27.0795 25.7775 33 18 33C9.7155 33 3 26.2845 3 18C3 10.2225 8.9205 3.8265 16.5 3.075V0.813V0.8145ZM16.5 19.5V6.093C13.4741 6.47802 10.7083 8.0008 8.76478 10.3518C6.82128 12.7029 5.84592 15.7057 6.03693 18.75C6.22794 21.7944 7.571 24.6518 9.79312 26.7414C12.0152 28.8311 14.9497 29.9962 18 30C20.9229 30 23.7453 28.9332 25.9376 27C28.1299 25.0668 29.5413 22.4 29.907 19.5H16.5ZM32.172 16.5C31.8281 13.2569 30.3825 10.2297 28.0764 7.92358C25.7703 5.61747 22.7431 4.17188 19.5 3.828V16.5H32.172Z"
                    fill="#2853E8" />
            </svg>
            <div>
                <h4>Total de estoque</h4>
                <h3><span id="total_em_estoque_kg"> </span> KG</h3>
            </div>
        </div>
        <div>
            <div>
                <p>Isso representa <strong><span class="green"><span id="total_em_estoque_percent">
                            </span>%</span></strong>
                    do total de reprocessos gerados</p>
            </div>
        </div>
    </div>
    <div [attr.aria-disabled]="isLoading">
        <div>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3531_562)">
                    <path
                        d="M7.5 4.5V28.5H31.5V31.5H4.5V4.5H7.5ZM30.4395 9.4395L32.5605 11.5605L24 20.121L19.5 15.6225L13.0605 22.0605L10.9395 19.9395L19.5 11.379L24 15.8775L30.4395 9.4395Z"
                        fill="#2853E8" />
                </g>
                <defs>
                    <clipPath id="clip0_3531_562">
                        <rect width="36" height="36" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <div>
                <h4>Reprocessos gerados</h4>
                <h3><span id="total_reprocessos_gerados_kg"> </span> KG</h3>
            </div>
        </div>
        <div>
            <div>
                <p>Em peso total de reprocessos gerados</p>
            </div>
        </div>
    </div>
    <div [attr.aria-disabled]="isLoading" class="more-details">
        <h4>Mais sobre Reprocessos</h4>
        <a href="reprocessos"><i class="fa-solid fa-up-right-from-square"></i> VEJA AQUI</a>
    </div>
</section>