<main>
    <h1>
        <span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0002 19.1668C4.93741 19.1668 0.833496 15.0629 0.833496 10.0002C0.833496 4.93741 4.93741 0.833496 10.0002 0.833496C15.0629 0.833496 19.1668 4.93741 19.1668 10.0002C19.1668 15.0629 15.0629 19.1668 10.0002 19.1668ZM5.42875 15.7348C6.72633 16.7722 8.33887 17.3361 10.0002 17.3335C11.806 17.3335 13.4587 16.6808 14.7366 15.5992C14.1396 14.9866 13.4258 14.5 12.6374 14.1681C11.8491 13.8362 11.0022 13.6657 10.1468 13.6668C9.26003 13.6658 8.38271 13.8491 7.57051 14.2051C6.75831 14.5611 6.02896 15.082 5.42875 15.7348ZM4.14816 14.4185C4.91858 13.6009 5.84828 12.9497 6.87996 12.5052C7.91164 12.0606 9.02344 11.832 10.1468 11.8335C11.23 11.8321 12.3028 12.0447 13.3036 12.459C14.3044 12.8734 15.2135 13.4813 15.9787 14.248C16.7634 13.1435 17.2268 11.8432 17.3175 10.4914C17.4082 9.13959 17.1226 7.78906 16.4924 6.58966C15.8623 5.39027 14.9121 4.3889 13.7475 3.69665C12.5828 3.00441 11.2491 2.64835 9.89438 2.66798C8.53966 2.68761 7.21685 3.08216 6.07272 3.80786C4.92859 4.53356 4.00788 5.56203 3.41271 6.77918C2.81755 7.99633 2.57122 9.35456 2.70104 10.7032C2.83086 12.0518 3.33175 13.3381 4.14816 14.4194V14.4185ZM10.0002 10.9168C9.0277 10.9168 8.09507 10.5305 7.40744 9.84289C6.7198 9.15525 6.3335 8.22262 6.3335 7.25016C6.3335 6.2777 6.7198 5.34507 7.40744 4.65744C8.09507 3.9698 9.0277 3.5835 10.0002 3.5835C10.9726 3.5835 11.9053 3.9698 12.5929 4.65744C13.2805 5.34507 13.6668 6.2777 13.6668 7.25016C13.6668 8.22262 13.2805 9.15525 12.5929 9.84289C11.9053 10.5305 10.9726 10.9168 10.0002 10.9168ZM10.0002 9.0835C10.4864 9.0835 10.9527 8.89034 11.2965 8.54653C11.6403 8.20271 11.8335 7.73639 11.8335 7.25016C11.8335 6.76393 11.6403 6.29762 11.2965 5.9538C10.9527 5.60998 10.4864 5.41683 10.0002 5.41683C9.51393 5.41683 9.04762 5.60998 8.7038 5.9538C8.35998 6.29762 8.16683 6.76393 8.16683 7.25016C8.16683 7.73639 8.35998 8.20271 8.7038 8.54653C9.04762 8.89034 9.51393 9.0835 10.0002 9.0835Z"
                    fill="#6F7480" />
            </svg>
            &nbsp;
            Minha conta
        </span>
        <quick-access-buttons></quick-access-buttons>
    </h1>

    <section class="infos">
        <label>
            <span>Nome</span>
            <input type="text" value="Seu Nome Aqui" id="nome">
        </label>
        <label>
            <span>E-mail</span>
            <input type="email" value="email@email.com" disabled id="email">
        </label>
        <div>
            <span>Seus Acessos</span>
            <ul id="acessos">
                <li>Nenhum acesso atribuído</li>
            </ul>
        </div>
        <button disabled id="alterar">Alterar</button>
    </section>

    <section id="admin-panel" class="admin-panel">
        <p>Você está vendo esse painel porque é um administrador</p>
        <p class="black">Usuários da plataforma:</p>
        <div id="users" class="users">
            <div *ngFor="let user of users">
                <span>{{ user.nome }}</span>
                <span>{{ user.email }}</span>
                <button class="edit" (click)="openEditUser(user)">Editar</button>
            </div>
        </div>
        <button class="create" popoverTarget="user-create">Criar usuário</button>

        <div id="user-create" class="user-create" popover>
            <label>
                <span>Nome</span>
                <input type="text" placeholder="Nome Aqui" id="nome-create">
            </label>
            <label>
                <span>E-mail</span>
                <input type="email" placeholder="email@email.com" id="email-create">
            </label>
            <label>
                <span>Senha</span>
                <input type="password" placeholder="Senha" id="senha-create">
            </label>
            <div>
                <span>Acessos</span>
                <label *ngFor="let module of modules" id="acessos-create">
                    <input type="checkbox" id="{{module.nome}}">
                    <span>{{module.nome}}</span>
                </label>
            </div>
            <div>
                <span>É admin?</span>
                <label>
                    <input type="checkbox" id="admin-create">
                    <span>Sim</span>
                </label>
            </div>
            <p class="info" id="info-create"></p>
            <div class="buttons">
                <button id="criar" (click)="createUser()">Criar</button>
                <button class="outlined" popoverTarget="user-create">Cancelar</button>
            </div>
        </div>

        <div id="user-edit" class="user-edit" popover>
            <h4>Editar</h4>
            <label>
                <span>Nome</span>
                <input type="text" value="Seu Nome Aqui" disabled id="nome-edit">
            </label>
            <label>
                <span>E-mail</span>
                <input type="email" value="email@email.com" disabled id="email-edit">
            </label>
            <div>
                <h5>Acessos</h5>
                <ul id="acessos-edit" class="acessos">
                    <li>
                        <label *ngFor="let module of modules">
                            <input type="checkbox" id="{{module.nome}}">
                            <span>{{module.nome}}</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div>
                <span>É admin?</span>
                <label>
                    <input type="checkbox" id="admin-edit">
                    <span>Sim</span>
                </label>
            </div>
            <div>
                <span>Está ativo?</span>
                <label>
                    <input type="checkbox" id="ativado-edit">
                    <span>Sim</span>
                </label>
            </div>
            <p class="info" id="info-edit"></p>
            <div class="buttons">
                <button (click)="editUser()" id="editar">Alterar</button>
                <button class="outlined" popoverTarget="user-edit">Cancelar</button>
            </div>
        </div>
    </section>
</main>

<loading [isLoading]="isLoading"></loading>