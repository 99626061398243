<main>
    <h1>
        <span>
            <span *ngIf="hournow >= 6 && hournow < 12">
                <i class="fa-solid fa-mountain-sun"></i>&nbsp;&nbsp;Bom dia!
            </span>
            <span *ngIf="hournow >= 12 && hournow < 18">
                <i class="fa-regular fa-sun"></i>&nbsp;&nbsp;Boa tarde!
            </span>
            <span *ngIf="hournow >= 18 || hournow < 6">
                <i class="fa-regular fa-moon"></i>&nbsp;&nbsp;Boa noite!
            </span>
        </span>
        <quick-access-buttons></quick-access-buttons>
    </h1>

    <reprocessos></reprocessos>

    <div>
        <label>
            Linha
            <select id="linha" (change)="filterByLinha()">
                <option>TODAS</option>
            </select>
        </label>
        <label>
            <span>Grupo <i id="whySelectGrupo" class="fa-regular fa-circle-question tooltip"></i></span>
            <select id="grupo" (change)="filterByGrupo()">
                <option>TODOS</option>
            </select>
        </label>
        <label>
            Categoria
            <select id="categoria" (change)="filterByCategoria()">
                <option>TODAS</option>
            </select>
        </label>
        <label>
            <span>Lote</span>
            <div>
                <select id="lote" (change)="filterByLote()">
                    <option>TODOS</option>
                </select>
            </div>
        </label>
        <label>
            <span style="color: #2853E8;">Produto</span>
            <div>
                <select id="produto">
                </select>
            </div>
        </label>
        <label>
            Turno
            <select id="turno">
                <option>TODOS</option>
                <option>Primeiro Turno</option>
                <option>Segundo Turno</option>
            </select>
        </label>
        <label>Menor Peso (g) <input id="minPeso" type="number" min="0" [defaultValue]="0"></label>
        <label>Maior Peso (g)<input id="maxPeso" type="number" max="60" [defaultValue]="60"></label>
        <label>Data inicial <input id="initialDate" type="date"></label>
        <label>Data Final <input id="finalDate" type="date"></label>
        <button id="add_products" (click)="addsDataToCharts()" [disabled]="isLoading">Filtrar</button>
    </div>

    <div id="added_products">
        <span *ngIf="this.added_products.length <= 0">Selecione um <strong style="color: #2853E8;">Produto</strong> e
            clique em <strong style="color: #2853E8;">Filtrar</strong> para começar</span>
        <span *ngFor="let product of added_products">{{product}} <button (click)="removeDataToCharts(product)"><i
                    class="fa-solid fa-x"></i></button></span>
    </div>

    <section>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 1</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart1"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart1" popover>
                <h3>GRÁFICO 1</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart1" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 2</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart2"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart2" popover>
                <h3>GRÁFICO 2</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart2" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 3</h3>
            <hr>
            <div>
                <div></div>
            </div>
            <button popovertarget="chart3"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart3" popover>
                <h3>GRÁFICO 3</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <div></div>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart3" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 4</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart4"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart4" popover>
                <h3>GRÁFICO 4</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart4" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div class="smalldiv" [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 5</h3>
            <hr>
            <div>
                <div class="table"></div>
            </div>
            <button popovertarget="chart5"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart5" popover>
                <h3>GRÁFICO 5</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <div class="table"></div>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart5" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 6</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart6"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart6" popover>
                <h3>GRÁFICO 6</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart6" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 7</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart7"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart7" popover>
                <h3>GRÁFICO 7</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart7" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading" class="more-details">
            <h4>Mais sobre Qualidade</h4>
            <a href="controle-qualidade"><i class="fa-solid fa-up-right-from-square"></i> VEJA AQUI</a>
        </div>
    </section>

    <div class="tooltip"></div>

</main>