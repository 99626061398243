<side-nav></side-nav>
<div class="main-content" [class]="{ 'loginPage': isLogInPage }">
    <router-outlet></router-outlet>

    <dialog id="deactivated-user">
        <h4><i class="fa-solid fa-triangle-exclamation"></i> Usuário está inativo!</h4>
        <p>Acredita que isso é um erro? Entre em contato com o administrador do sistema.</p>
        <button (click)="logOut()">
            Sair
        </button>
    </dialog>
</div>